// code related to api configurations

// unsplash api keys
// e4nxVVv2PD3SO17_JzjfqwFE-ADAvGC6ILRr5WgqeVw
// hWgYrb2y3Afv0Lo3O0dusbcGKcZF1NI12NPUFYg7Jxg

import axios from 'axios';

// Create an instance of axios with default parameters to make it easier to call in our applications
// 

export default axios.create({
    baseURL: 'https://pulsepromise.com'
});

