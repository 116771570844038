import react from 'react';
import './css/VideoEmbed.css';



const VideoEmbed = () =>{

return (
    <div className="videoembed-content">
                <iframe 
                width="754" 
                height="424" src="https://www.youtube.com/embed/MMHEPFrMfhY" title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
                </iframe>
    </div>
        );

}

export default VideoEmbed;
