import React, {Component} from 'react';
import '../App.css';
import audit from '../apis/audit';

import DataTable from 'react-data-table-component';
import axios from 'axios';
import _ from 'lodash';

const columns = [
    {
        name: 'pageview',
        label: 'PAGevIEW',
        selector: row => row.pageview,
        sortable: true,
    },
    {
        name: 'cct',
        label: 'COUNTSSSSS',
        selector: row => row.cct,
        sortable: true,
    },
];

// const data = [
//     {
//         id: 1,
//         title: 'Beetlejuice',
//         year: '1988',
//     },
//     {
//         id: 2,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
// ]


class Counts extends Component {

  state = {
        images: [],
        tabledata: []
    }

    onSearchSubmit = async (term) => {

    const response = await audit.get('https://pulsepromise.com/zztest.php', {
        params: {query: term}
    });
    // LOCAL TESTING
      // var response = {"code":200,"message":"ok","data":{"message":"success","totalcount":"116","added_at":1636053300}};
      // this.setState({totalcount: response.data.totalcount});

    console.log(response.data);
    // this.setState({images: response.data.});
    // console.log("Post Api Requesta");
  }
  
  onCountData = async () => {

    const ax = axios.create({baseURL: "/"});
    const response = await ax.get('/ztest.json', { params: {}    });

    // let newdata = this.conditionData(response.data);
    console.log(response.data.data.totalcount);
    console.log(response.data.data.counts);
    
    this.setState({totalcount: response.data.data.totalcount});

    // this.setState({images: response.data.});
    // console.log("Post Api Requesta");
    return response.data.data.counts;
  }
  
  conditionData = (tablein) => {
    let tout = [];
    let i = 0;
    const tableout = tablein.map( (myrow, index)=>{
      let zz = {};
      zz["id"] = index;
      zz['pageview'] = myrow['pageview'];
      zz['cct'] = myrow['cct'];
      console.log(zz);
      i++;
    })


  }

  componentDidMount() {
    
    console.log("Counts have been started");
    
    // Live Page
    this.onSearchSubmit('counts');

    // let mydata = this.onCountData();
    // console.log(this.onCountData());
    // this.setState({tabledata:  mydata});

  }

  render() {
  return (
    <div className="container">
      <div>
      Group 1
      Total Count: {this.state.totalcount}
      </div>
      <div className="ui three column grid">
        <div className="row">

      <div className="column two">
      </div>

      <div className="column">
{/*      <DataTable
            columns={columns}
            data={this.state.tabledata}
            dense
            direction="auto"
            fixedHeaderScrollHeight="300px"
            highlightOnHover
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
          />*/}
          </div>
          <div className="column two">
          </div>
          </div>
          </div>
    </div>
  );
  }
}

export default Counts;
