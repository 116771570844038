import React, {Component} from 'react';

import '../App.css';

import audit from '../apis/audit';
import TelegramLink from './TelegramLink';
import VideoEmbed from './VideoEmbed';



class Main extends Component {

  state = {
        images: [],
        totalcount: 0
    }

    onSearchSubmit = async (term) => {

      const response = await audit.get('https://pulsepromise.com/audittest.php', {
          params: {query: term}
      });




      console.log(response.data);

      // this.setState({images: response.data.});
      // console.log("Post Api Requesta");
    }
  
    componentDidMount() {
      
      console.log("Main has been Mounted");
      
      this.onSearchSubmit('page1');

    }

  render() {

  return (

    <div className="container">

      <header className="App-header">
          


        <img src="/assets/Pulse_Promise_Splash3-01.jpeg" className="App-logo" alt="logo" />
        <p>
          
        </p>
        
          <TelegramLink />

          <VideoEmbed />

      </header>

    </div>
  );
  }
}

export default Main;

