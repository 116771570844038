import react from 'react';
import './css/TelegramLink.css';



const TelegramLink = () =>{

return (
    <div className="telegram-content">
        <a href="https://t.me/TheCompassionCartel" target="_blank" className="telegramlink-atag">
        Click here to Join The Compassion Cartel Telegram Group!
        </a>
    </div>
        );

}

export default TelegramLink;
