import React, {Component} from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './App.css';

import audit from './apis/audit';

import Main from './components/Main';
import Counts from './components/Counts';


class App extends Component {

  componentDidMount() {
    
    console.log("I have been started");
    
    // this.onSearchSubmit('page1');
    // this.onCountData();

  }

  render() {
  return (

    <div className="App">
    <BrowserRouter>
       <Switch>
          <Route exact path="/">
            <Main />
          </Route>
          <Route path="/counts">
            <Counts />
          </Route>
        </Switch>
    </BrowserRouter>
    </div>
  );
  }
}

export default App;
